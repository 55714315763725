// in src/users.js
import React from 'react';
import { List, Edit, Create, Datagrid, TextField, SimpleForm, TextInput, Show, SimpleShowLayout,  EmailField  } from 'admin-on-rest';
import { ShowButton, EditButton, DeleteButton } from 'admin-on-rest';

//import { List, Datagrid, EmailField, TextField } from 'admin-on-rest';

const UserTitle = ({ record }) => {
    return <span>Cron {record ? `#${record.id}` : ''}</span>;
};

const userRowStyle = (record, index) => ({
    backgroundColor: record.id > 1 ?  'white' : '#ddd',
});

export const UserList = (props) => (
    <List title="All users" {...props} perPage={25} sort={{ field: 'id', order: 'ASC' }}>
        <Datagrid rowStyle={userRowStyle}>
            <TextField source="id" />
            <TextField source="name" />
            <EmailField source="email" />
            <ShowButton />
            <EditButton />
            <DeleteButton />
        </Datagrid>
    </List>
);

export const UserShow = (props) => (
    <Show title={<UserTitle />} {...props}>
        <SimpleShowLayout>
            <TextField source="name" />
            <EmailField source="email" />
            <TextField label="token" source="token" />
        </SimpleShowLayout>
    </Show>
);

export const UserCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" />
            <TextInput source="email" type="email" />
            <TextInput source="password" type="password" />
            <TextInput source="password_confirmation" type="password" />
        </SimpleForm>
    </Create>
);

export const UserEdit = (props) => (
    <Edit title={<UserTitle />} {...props}>
        <SimpleForm>
            <TextInput source="name" />
            <TextInput source="email" type="email" />
            <TextInput source="password" type="password" />
            <TextInput source="password_confirmation" type="password" />
        </SimpleForm>
    </Edit>
);