// in src/crontab.js
import React from 'react';
import { ReferenceManyField, Filter, List, Edit, Create, Datagrid, TextField, DisabledInput, LongTextInput, SimpleForm, TextInput, Show, SimpleShowLayout,  DateField, BooleanField, NumberField, BooleanInput, ShowButton, DeleteButton } from 'admin-on-rest';
import RefreshListActions from './RefreshListActions';

const CronRowStyle = (record, index) => ({
    backgroundColor: record.enabled > 0 ? ( record.crontab_error > 0 ? '#fdd' : 'white') : '#ddd',
});

const HistoryRowStyle = (record, index) => ({
    backgroundColor: record.id%2?'white':'#eef',
});

const CronTitle = ({ record }) => {
    return <span>Cron {record ? `#${record.id}` : ''}</span>;
};

const CronFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <BooleanInput label="Enable" source="enabled" defaultValue="true" />
        <BooleanInput label="VPS" source="vps" defaultValue="true" />
        <BooleanInput label="Error" source="crontab_error" defaultValue="true" />
    </Filter>
);

const ErrorField = ({ record = {} }) => <span>{(record.crontab_error>0?(record.crontab_error>1?'ERROR':'TIMEOUT'):'OK')}</span>;

export const CronList = (props) => (
    <List {...props} title="List of crons" perPage={25} filters={<CronFilter />} sort={{ field: 'crontab_error', order: 'DESC' }} actions={<RefreshListActions refreshInterval="30000" />}>
        <Datagrid rowStyle={CronRowStyle}>
            <NumberField  source="id" />
            <TextField source="user" />
            <TextField source="server" />
            <ErrorField label="Status" source="crontab_error" />
            <TextField source="cron" />
            <TextField source="task" />
            <ShowButton />
            <DeleteButton />
        </Datagrid>
    </List>
);

export const CronEdit = (props) => (
    <Edit title={<CronTitle />} {...props}>
        <SimpleForm>
            <DisabledInput source="uuid" />
            <BooleanInput source="enabled" />
            <TextInput source="user" />
            <TextInput source="server" />
            <TextInput source="cron" />
            <TextInput source="task" />
        </SimpleForm>
    </Edit>
);


export const CronCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="user" />
            <TextInput source="server" />
            <TextInput source="cron" />
            <LongTextInput source="task" />
        </SimpleForm>
    </Create>
);

export const CronShow = (props) => (
    <Show title={<CronTitle />} {...props}>
        <SimpleShowLayout>
            <TextField source="uuid" />
            <BooleanField source="enabled"   style={{  width: 24 }} />
            <TextField source="user" />
            <TextField source="server" />
            <TextField source="cron" />
            <TextField source="task" />
            <TextField label="Error" source="crontab_error" />
            <TextField source="result_code" />
            <TextField label="Result text" source="crontab_result" />
            <DateField label="updated" source="crontab_at" showTime />

            <ReferenceManyField perPage={10} label="Last errors" reference="error" target="id">
                <Datagrid rowStyle={HistoryRowStyle}>
                    <DateField label="Date" source="crontab_at" showTime sortable={false}/>
                    <TextField label="Error text"  source="crontab_result" sortable={false}/>
                </Datagrid>
            </ReferenceManyField>

        </SimpleShowLayout>
    </Show>
);
