import React from 'react';
import Dashboard from './Dashboard';
import { CronList, CronEdit, CronCreate, CronShow } from './crontab';
import { DaemonList, DaemonShow } from './daemon';
import { UserList, UserShow, UserCreate, UserEdit } from './users';
import { HistoryList } from './history';
import authClient from './authClient';
import { jsonServerRestClient, fetchUtils, Admin, Resource, Delete } from 'admin-on-rest';
import UserIcon from 'material-ui/svg-icons/social/group';
import HistoryIcon from 'material-ui/svg-icons/action/history';

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = localStorage.getItem('token');
    options.headers.set('Authorization', `Bearer ${token}`);
    return fetchUtils.fetchJson(url, options);
}

const App = () => (
    <Admin title={process.env.REACT_APP_SITE_TITLE} dashboard={Dashboard} authClient={authClient} restClient={jsonServerRestClient(process.env.REACT_APP_URL + '/api', httpClient)}>
            <Resource name="crontab" options={{ label: 'Crons' }}  list={CronList} show={CronShow} edit={CronEdit} create={CronCreate} remove={Delete}/>
            <Resource name="daemon" options={{ label: 'Daemons' }}  list={DaemonList} show={DaemonShow} remove={Delete}/>
            <Resource name="error" options={{ label: 'Errors' }} icon={HistoryIcon} list={HistoryList} />
            <Resource name="user" options={{ label: 'Users' }} list={UserList} icon={UserIcon} show={UserShow} edit={UserEdit} create={UserCreate} remove={Delete}/>
    </Admin>
                );

export default App;
