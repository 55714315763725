import React from 'react';
import { List,  Datagrid, TextField } from 'admin-on-rest';

const historyRowStyle = (record, index) => ({
    backgroundColor: record.id%2?'white':'#eef',
});

const CronUrlField = ({ record = {} }) => <span>{record.crontab_at} <a href={record.url}>{record.user}@{record.server}:{record.cid}</a></span>;

export const HistoryList = (props) => (
    <List title="Errors at last 24h" {...props} perPage={25} sort={{ field: 'crontab_at', order: 'DESC' }} >
        <Datagrid rowStyle={historyRowStyle}>
            <CronUrlField  label="Date" source="crontab_at" sortable={false} />
            <TextField label="Error text" source="crontab_result" sortable={false} />
        </Datagrid>
    </List>
);

