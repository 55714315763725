import React from 'react';
import logo from './logo.svg';
import './App.css';

export default () => (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
                <p>
                    Welcome to Cron-Admin panel.
                </p>
                <a
                    className="App-link"
                    href="//cron1.feip.co/cron-admin/#/crontab"
                    rel="noopener noreferrer"
                >
                    Manage Crons
                </a>
            </header>
        </div>
);
