import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK} from 'admin-on-rest';

export default (type, params) => {
    if (type === AUTH_LOGIN) {
        const { username, password } = params;
        const request = new Request(process.env.REACT_APP_URL + '/api/login', {
            method: 'POST',
            body: JSON.stringify({ username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        })
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(({ token }) => {
                localStorage.setItem('token', token);
            });
    }

    if (type === AUTH_LOGOUT) {

        const token = localStorage.getItem('token');
//        options.headers.set('Authorization', `Bearer ${token}`);
  //      return fetchUtils.fetchJson(url, options);

        const request = new Request(process.env.REACT_APP_URL + '/api/logout', {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json', 'Authorization' : `Bearer ${token}`}),
        })
        fetch(request);
        localStorage.removeItem('token');
        return Promise.resolve();
    }

    if (type === AUTH_ERROR) {
        const status  = params.message.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('token');
            return Promise.reject();
        }
        return Promise.resolve();
    }
    if (type === AUTH_CHECK) {
        return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
    }
    //return Promise.resolve();
    return Promise.reject('Unkown method');
}
