// in src/crontab.js
import React from 'react';
import { ReferenceManyField, Filter, List, Edit, Create, Datagrid, TextField, DisabledInput, LongTextInput, SimpleForm, TextInput, Show, SimpleShowLayout,  DateField, BooleanField, NumberField, BooleanInput, ShowButton, DeleteButton } from 'admin-on-rest';
import RefreshListActions from './RefreshListActions';

const DaemonRowStyle = (record, index) => ({
    backgroundColor: record.enabled > 0 ? ( record.sub != 'running' ? '#fdd' : 'white') : '#ddd',
});

const DaemonTitle = ({ record }) => {
    return <span>Daemon {record ? `#${record.id}` : ''}</span>;
};

const DaemonFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <BooleanInput label="Enable" source="enabled" defaultValue="true" />
        <BooleanInput label="VPS" source="vps" defaultValue="true" />
        <BooleanInput label="Error" source="daemon_error" defaultValue="true" />
    </Filter>
);

export const DaemonList = (props) => (
    <List {...props} title="List of daemons" perPage={25} filters={<DaemonFilter />} sort={{ field: 'sub', order: 'ASC' }} actions={<RefreshListActions refreshInterval="30000" />}>
        <Datagrid rowStyle={DaemonRowStyle}>
            <NumberField  source="id" />
            <TextField source="user" />
            <TextField source="server" />
            <TextField source="unit" />
            <TextField source="load" />
            <TextField source="active" />
            <TextField source="sub" />
            <ShowButton />
            <DeleteButton />
        </Datagrid>
    </List>
);

export const DaemonShow = (props) => (
    <Show title={<DaemonTitle />} {...props}>
        <SimpleShowLayout>
            <TextField source="uuid" />
            <BooleanField source="enabled"   style={{  width: 24 }} />
            <TextField source="user" />
            <TextField source="server" />
            <TextField source="unit" />
            <TextField source="load" />
            <TextField source="active" />
            <TextField source="sub" />
            <TextField source="enable" />
            <TextField source="description" />
            <TextField source="status" sortable={false}/>
            <DateField label="updated" source="updated_at" showTime />
            <DateField label="Error date" source="error_at" showTime sortable={false}/>
            <TextField label="Error text"  source="daemon_result" sortable={false}/>

        </SimpleShowLayout>
    </Show>
);
